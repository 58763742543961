import logger from 'loglevel'
import CMPHelper from '../CMP/CMPHelper'
import EBAConfig from '../EBAConfig/EBAConfig'

function GemiusSubscriberFactory() {
  let installed = false
  let initializeAttempts = 0

  const stats = {
    eventsProcessed: 0,
    eventsDelivered: 0,
    eventsFailed: 0,
  }

  // =========
  // privates
  // =========

  const shouldPushEvent = (ev) =>
    !(
      EBAConfig.in_app &&
      EBAConfig.gemius_appSkipList.find((skipSchemaName) =>
        ev.event.schema.startsWith(skipSchemaName),
      )
    )

  const pushEventToGemius = (ev) => {
    logger.debug('EBA: pushEventToGemius')
    logger.debug(JSON.stringify(ev))
    stats.eventsProcessed += 1
    if (shouldPushEvent(ev)) {
      try {
        if (ev.event.schema.startsWith('iglu:be.vrt/page_impression/')) {
          window.pp_gemius_hit(EBAConfig.gemius_pageTrackingId, 'lan=NL')
          stats.eventsDelivered += 1
        }
      } catch (e) {
        stats.eventsFailed += 1
        throw e
      }
    } else {
      logger.debug('EBA: should not push event to Gemius')
    }
  }

  const initializeGemius = () => {
    if (
      initializeAttempts < EBAConfig.gemius_cmpMaxAttempts &&
      !CMPHelper.isCMPReady()
    ) {
      initializeAttempts += 1
      logger.info('EBA GemiusSubscriber: still waiting for CMP dependency')
      setTimeout(() => initializeGemius(), EBAConfig.gemius_cmpWaitMillis)
    } else {
      window.pp_gemius_extraparameters = new Array('lan=NL')
      window.pp_gemius_use_cmp = true
      window.pp_gemius_cmp_timeout = Number.POSITIVE_INFINITY
      window.pp_gemius_cmp_purposes = { 2: [1, 8, 9, 10] }

      const gemius_pending = (i) => {
        window[i] =
          window[i] ||
          function f(...args) {
            let x = window[`${i}_pdata`]
            if (!x) {
              x = window[`${i}_pdata`] = []
            }
            x[x.length] = args
          }
      }

      gemius_pending('gemius_hit')
      gemius_pending('gemius_event')
      gemius_pending('pp_gemius_hit')
      gemius_pending('pp_gemius_event')
      ;((d, t) => {
        try {
          const gt = d.createElement(t)
          const s = d.getElementsByTagName(t)[0]
          const l = `http${location.protocol === 'https:' ? 's' : ''}`
          gt.setAttribute('async', 'async')
          gt.setAttribute('defer', 'defer')
          gt.src = `${l}://gabe.hit.gemius.pl/xgemius.js`
          s.parentNode.insertBefore(gt, s)
        } catch (_e) {
          logger.warn('gemius init error')
        }
      })(document, 'script')

      window.digitalData.events.push({ subscribe: pushEventToGemius })
      installed = true
    }
  }

  // =========
  // publics
  // =========

  const install = () => {
    try {
      if (EBAConfig.gemius_enabled && !installed) {
        initializeGemius()
      }
    } catch (_e) {
      logger.warn('EBA: failed to install GemiusSubscriber')
    }
  }

  const uninstall = () => {
    logger.debug('EBA GemiusSubscriber: uninstalling')
  }

  return {
    install,
    uninstall,
    stats,
  }
}

export default GemiusSubscriberFactory()
