import logger from 'loglevel'
import EBAConfig from '../EBAConfig/EBAConfig'
import { formatDate, parseContexts } from '../utils'

function SmartoctoSubscriberFactory() {
  const ARTICLE_PAGE_TYPES = ['article', 'matchfiche', 'detail']
  const PAGE_IMPRESSION_SCHEMA = 'iglu:be.vrt/page_impression/'

  let installed = false
  const stats = {
    eventsProcessed: 0,
    eventsDelivered: 0,
    eventsFailed: 0,
  }

  const loadTentaclesScript = () => {
    try {
      const script = document.createElement('script')
      const firstScript = document.getElementsByTagName('script')[0]
      const protocol = location.protocol === 'https:' ? 'https' : 'http'
      const TENTACLES_URL = '//tentacles.smartocto.com/ten/tentacle.js'

      script.setAttribute('async', 'async')
      script.setAttribute('defer', 'defer')
      script.src = `${protocol}:${TENTACLES_URL}`

      if (!firstScript?.parentNode) {
        throw new Error(
          'EBA SmartoctoSubscriber: No valid script element found to insert Tentacles script',
        )
      }

      firstScript.parentNode.insertBefore(script, firstScript)
    } catch (error) {
      logger.warn('EBA SmartoctoSubscriber: Tentacles init error:', error)
      stats.eventsFailed += 1
    }
  }

  const handleSmartoctoEvent = (event) => {
    const { ctxes } = parseContexts(event)

    if (!event.event.schema.startsWith(PAGE_IMPRESSION_SCHEMA)) return

    let sections

    if (ctxes?.context_content_news?.newstheme) {
      sections = ctxes.context_content_news.newstheme.replace(/\|/g, '>')
    } else if (ctxes?.context_content_sport) {
      sections =
        [
          ctxes.context_content_sport.sportname,
          ctxes.context_content_sport.competitionname,
        ]
          .filter(Boolean)
          .join('>') || undefined
    }

    window._ain = {
      postId: ctxes?.context_page?.pageid,
      title: ctxes?.context_page?.pagetitle,
      url: ctxes?.context_page?.pageurl,
      article_type: ctxes?.context_content_article?.articletype,
      access_level: 'free',
      authors: ctxes?.context_content_article?.articleauthor,
      sections,
      tags: ctxes?.context_page?.labels,
      has_video: Boolean(ctxes?.context_content_article?.articlevideocount),
      pubdate: formatDate(ctxes?.context_content_article?.publishtimestamp, {
        toISO: true,
      }),
      updateTimestamp: formatDate(
        ctxes?.context_content_article?.updatetimestamp,
        { toISO: true },
      ),
    }

    if (ctxes?.context_page?.sitesection1) {
      window.tentacles.pageType = ARTICLE_PAGE_TYPES.includes(
        ctxes?.context_page?.sitesection1,
      )
        ? 'article'
        : 'landing'
    }
    window.tentacles.trackauto = true
    window.postMessage('activateTentacles')
  }

  const initializeSmartocto = () => {
    window._ain = window._ain || {}
    window.tentacles = {
      apiToken: EBAConfig.smartocto_tentacles_apiToken,
      trackauto: false,
    }

    loadTentaclesScript()

    const pushEventToSmartocto = (event) => {
      logger.debug('EBA: pushEventToSmartocto', JSON.stringify(event))
      stats.eventsProcessed += 1

      try {
        handleSmartoctoEvent(event)
        stats.eventsDelivered += 1
      } catch (error) {
        stats.eventsFailed += 1
        throw error
      }
    }

    window.digitalData.events.push({ subscribe: pushEventToSmartocto })
    installed = true
    logger.info('EBA SmartoctoSubscriber: Successfully initialized')
  }

  const install = () => {
    try {
      if (!EBAConfig.smartocto_enabled || installed) {
        logger.debug(
          'EBA SmartoctoSubscriber: Skip installation: Already installed or disabled',
        )
        return
      }
      initializeSmartocto()
    } catch (error) {
      logger.error('EBA SmartoctoSubscriber: Failed to install', error)
      throw error
    }
  }

  const uninstall = () => {
    logger.debug('EBA SmartoctoSubscriber: Uninstalling')
  }

  return {
    install,
    uninstall,
    stats,
  }
}

export default SmartoctoSubscriberFactory()
